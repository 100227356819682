import * as React from 'react';
import {
  StripSlideshowProps,
  StripSlideshowImperativeActions,
} from '../StripSlideshow.types';
import TPAGallery from '../../TPAGallery/viewer/TPAGallery';

const StripSlideshow: React.ForwardRefRenderFunction<
  StripSlideshowImperativeActions,
  StripSlideshowProps
> = (props, forwardRef) => {
  return <TPAGallery {...props} forwardRef={forwardRef}></TPAGallery>;
};

export default React.forwardRef(StripSlideshow);
